#shard-calculator {
  font-family: sans-serif;
}

#calculator-container {
  display: flex;
}

.shard-select-container div.gate-container {
  float: left;
}

.shard-select-container label, .shard-select-container select {
  text-align: center;
  flex-direction: column;
  margin: 5px;
  display: flex;
}

.shard-select-container label {
  font-weight: bold;
}

.shard-select-container {
  border: 1px solid #000;
  border-radius: 5px;
}

.shard-select-container legend {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.total-shards-container {
  width: 100px;
  height: 100%;
  border: 1px solid #000;
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
  position: relative;
}

.total-shards-container h2 {
  background: #fff;
  margin: -8px 0 0;
  padding: 0 3px;
  font-size: 16px;
  line-height: 1;
  position: absolute;
  top: 0;
}

.total-shards-container p {
  text-align: center;
  font-size: 24px;
  font-weight: 800;
}

.gate-options button {
  cursor: pointer;
  color: #fff;
  background: #000a47;
  border: 0;
  border-radius: 5px;
  outline: none;
  margin: 10px 5px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.1;
  display: inline-block;
}

.gate-options button:hover, .gate-options button:focus {
  transition: all .1s;
  box-shadow: 0 0 #fff, 0 0 0 3px #1de9b6;
}

/*# sourceMappingURL=index.d1b9805f.css.map */
